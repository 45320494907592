// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCjp0LSHXfk4sBrTP0HKH9-nnTWVGc8EDM",
  authDomain: "make-up-c7243.firebaseapp.com",
  databaseURL: "https://make-up-c7243-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "make-up-c7243",
  storageBucket: "make-up-c7243.appspot.com",
  messagingSenderId: "295327620414",
  appId: "1:295327620414:web:157c843fc40f32ebfd206f",
  measurementId: "G-SBE2T87MV8"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
